import React from 'react'
// import axios from 'axios'
import styled, { css, keyframes } from 'styled-components'
import { connect } from 'react-redux'
// import InViewMonitor from 'react-inview-monitor'

import SEO from '../components/seo'

import * as Layout from '../layouts/layout'
import * as FadeIn from '../components/fadeIn'

const Company = ({ lang }) => (
  <>
    <SEO title="Company" />
    <Hero className="hero">
      <HeroHeader>
        <FadeIn.Left margin="0%">
          <h1>
            {lang === 'ja' && '会社概要'}
            {lang === 'en' &&
              'We have a mission to convey the taste of authentic Sichuan cuisine to Japan, cherishing the food culture of authentic Sichuan. We promise to deliver to customers with "Smiles" carefully, keeping the taste of authentic "Sichuan cuisine" authentic.'}
            {lang === 'zh' && '公司简介'}
          </h1>
        </FadeIn.Left>
        <FadeIn.Left margin="0%">
          <p>
            {lang === 'ja' &&
              '芙蓉苑グループを運営する、株式会社トライハード ダイニングの会社情報です。'}
            {lang === 'en' &&
              'We have a mission to convey the taste of authentic Sichuan cuisine to Japan, cherishing the food culture of authentic Sichuan. We promise to deliver to customers with "Smiles" carefully, keeping the taste of authentic "Sichuan cuisine" authentic.'}
            {lang === 'zh' &&
              '这是运营芙蓉苑集团的TryHard　DINING有限公司的信息。'}
          </p>
        </FadeIn.Left>
      </HeroHeader>
    </Hero>
    <Layout.Section>
      <Layout.Container>
        <Layout.Row>
          <Table>
            <tbody>
              <tr>
                <TableMenu>
                  {lang === 'ja' && '企業名'}
                  {lang === 'en' &&
                    'We have a mission to convey the taste of authentic Sichuan cuisine to Japan, cherishing the food culture of authentic Sichuan. We promise to deliver to customers with "Smiles" carefully, keeping the taste of authentic "Sichuan cuisine" authentic.'}
                  {lang === 'zh' && '公司名'}
                </TableMenu>
                <TableBody>
                  {lang === 'ja' &&
                    '株式会社トライハード ダイニング'}
                  {lang === 'en' &&
                    'We have a mission to convey the taste of authentic Sichuan cuisine to Japan, cherishing the food culture of authentic Sichuan. We promise to deliver to customers with "Smiles" carefully, keeping the taste of authentic "Sichuan cuisine" authentic.'}
                  {lang === 'zh' && 'TryHard　DINING有限公司'}
                </TableBody>
              </tr>
              <tr>
                <TableMenu>
                  {lang === 'ja' && '所在地'}
                  {lang === 'en' &&
                    'We have a mission to convey the taste of authentic Sichuan cuisine to Japan, cherishing the food culture of authentic Sichuan. We promise to deliver to customers with "Smiles" carefully, keeping the taste of authentic "Sichuan cuisine" authentic.'}
                  {lang === 'zh' && '地址'}
                </TableMenu>
                <TableBody>
                  {lang === 'ja' &&
                    '〒541-0056　大阪市中央区久太郎町2丁目5－19　トライハードビル'}
                  {lang === 'en' &&
                    'We have a mission to convey the taste of authentic Sichuan cuisine to Japan, cherishing the food culture of authentic Sichuan. We promise to deliver to customers with "Smiles" carefully, keeping the taste of authentic "Sichuan cuisine" authentic.'}
                  {lang === 'zh' &&
                    '〒541-0056　大阪市中央区久太郎町2丁目5－19　トライハードビル'}
                </TableBody>
              </tr>
              <tr>
                <TableMenu>
                  {lang === 'ja' && '電話番号'}
                  {lang === 'en' &&
                    'We have a mission to convey the taste of authentic Sichuan cuisine to Japan, cherishing the food culture of authentic Sichuan. We promise to deliver to customers with "Smiles" carefully, keeping the taste of authentic "Sichuan cuisine" authentic.'}
                  {lang === 'zh' && '电话号码'}
                </TableMenu>
                <TableBody>06-6484-6692</TableBody>
              </tr>
              <tr>
                <TableMenu>
                  {lang === 'ja' && '事業内容'}
                  {lang === 'zh' && '企业内容'}
                </TableMenu>
                <TableBody>
                  {lang === 'ja' && 'レストラン運営'}
                  {lang === 'zh' && '餐厅运营'}
                  <br />・{lang === 'ja' && '四川料理 芙蓉苑 長堀橋本店'}
                  {lang === 'zh' && '四川料理 芙蓉苑 長堀橋本店'}
                  <br />・{lang === 'ja' && '芙蓉麻婆麺 十三店'}
                  {lang === 'zh' && '芙蓉麻婆麺 十三店）'}
                  <br />・{lang === 'ja' && '芙蓉火鍋城 長堀橋店'}
                  {lang === 'zh' && '芙蓉火鍋城 長堀橋店'}
                  <br />・{lang === 'ja' && '芙蓉火鍋城 京都店'}
                  {lang === 'zh' && '芙蓉火鍋城 京都店'}
                  <br />・{lang === 'ja' && 'FUYOEN 泉南りんくう公園店'}
                  {lang === 'zh' && 'FUYOEN 泉南りんくう公園店'}
                  <br />・{lang === 'ja' && 'FUYOEN 心斎橋PARCO店'}
                  {lang === 'zh' && 'FUYOEN 心斎橋PARCO店'}
                  {/*<br />・{lang === 'ja' && '狐食堂 韓国料理 Someck & FUYOENエンタメ酒場 京都店'}
                  {lang === 'zh' && '狐食堂 韓国調理 Someck & FUYOENエンタメ酒場 京都店'}*/}
                  <br />・{lang === 'ja' && '芙蓉苑 渋谷PARCO店'}
                  {lang === 'zh' && '芙蓉苑 渋谷PARCO店'}
                  <br />・{lang === 'ja' && 'FUYOEN＆SOMECK CHINESE × KOREAN'}
                  {lang === 'zh' && 'FUYOEN＆SOMECK CHINESE × KOREAN'}
                </TableBody>
              </tr>
              <tr>
                <TableMenu>
                  {lang === 'ja' && '設立'}
                  {lang === 'en' &&
                    'We have a mission to convey the taste of authentic Sichuan cuisine to Japan, cherishing the food culture of authentic Sichuan. We promise to deliver to customers with "Smiles" carefully, keeping the taste of authentic "Sichuan cuisine" authentic.'}
                  {lang === 'zh' && '设立'}
                </TableMenu>
                <TableBody>2018年11月1日</TableBody>
              </tr>
              <tr>
                <TableMenu>
                  {lang === 'ja' && '代表者'}
                  {lang === 'en' &&
                    'We have a mission to convey the taste of authentic Sichuan cuisine to Japan, cherishing the food culture of authentic Sichuan. We promise to deliver to customers with "Smiles" carefully, keeping the taste of authentic "Sichuan cuisine" authentic.'}
                  {lang === 'zh' && '公司代表'}
                </TableMenu>
                <TableBody>
                  {lang === 'ja' && '代表取締役社長　大付楽洋'}
                  {lang === 'en' &&
                    'We have a mission to convey the taste of authentic Sichuan cuisine to Japan, cherishing the food culture of authentic Sichuan. We promise to deliver to customers with "Smiles" carefully, keeping the taste of authentic "Sichuan cuisine" authentic.'}
                  {lang === 'zh' && '董事长　大付乐洋'}
                </TableBody>
              </tr>
              <tr>
                <TableMenu>
                  {lang === 'ja' && '従業員数'}
                  {lang === 'zh' && '员工人数'}
                </TableMenu>
                <TableBody>
                  {lang === 'ja' && '126名（2022年1月現在）'}
                  {lang === 'zh' && '126名（2022年1月现在）'}
                  <br />
                  {lang === 'ja' && '正社員 36名'}
                  {lang === 'zh' && '正社员 36名）'}
                  <br />
                  {lang === 'ja' && 'アルバイト 90名'}
                  {lang === 'zh' && '打工・委托 90名'}
                </TableBody>
              </tr>
            </tbody>
          </Table>
        </Layout.Row>
      </Layout.Container>
    </Layout.Section>
  </>
)

const mapStateToProps = state => ({
  lang: state.lang,
})

export default connect(mapStateToProps)(Company)

const pink = '#e3007f'
const beige = '#fadcbe'
const lightBeige = '#FEF8F2'
const brown = '#603813'
const darkBrown = '#42210B'

const Hero = styled.div`
  position: relative;
  height: 66.6666666666vw;
  max-height: 600px;
  overflow: hidden;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(/img/company-hero.jpg) center/cover;
  animation: 15s ${Layout.ZoomBg} infinite;
`

const HeroHeader = styled.header`
  position: absolute;
  transform: translate(-50%, -50%);
  color: #fff;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: calc(100% - 60px);
  @media (min-width: 1025px) {
    max-width: 450px;
    left: 30%;
  }
  h1 {
    line-height: 2;
    letter-spacing: 10px;
    font-size: 1.5rem;
  }
  p {
    line-height: 1.4;
    letter-spacing: 5px;
  }
`

const TableBox = styled.div`
  color: ${brown};
  margin-bottom: 15px;
  @media (min-width: 415px) {
    margin-top: 15px;
  }
`

const Table = styled.table`
  width: 100%;
  tbody {
    tr:nth-child(odd) th {
      background: ${lightBeige};
    }
    tr:nth-child(even) th {
      background: #ececec;
    }
  }
`

const TableHeader = styled.th`
  background: ${darkBrown};
  border: 1px solid ${darkBrown};
  color: #fff;
  text-align: center;
  padding: 5px;
  width: 100%;
  colspan: 2;
`

const TableMenu = styled.th`
  color: ${darkBrown};
  border-top: 1px solid ${darkBrown};
  border-bottom: 1px solid ${darkBrown};
  border-right: 1px solid ${darkBrown};
  width: 30%;
  text-align: center;
  padding: 15px;
  vertical-align: middle;
`

const TableBody = styled.td`
  color: ${darkBrown};
  border-top: 1px solid ${darkBrown};
  border-bottom: 1px solid ${darkBrown};
  border-left: 1px solid ${darkBrown};
  width: 70%;
  padding: 15px;
  vertical-align: middle;
`
